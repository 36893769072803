<template>
  <div class="noticePage">
    <el-form ref="noticeFrom"
             label-width="90px">
      <el-form-item label="通知标题">{{title}}</el-form-item>
      <el-form-item label="发布人">{{operName}}</el-form-item>
      <el-form-item label="发布时间">{{operTime}}</el-form-item>
      <!-- <el-form-item label="资料提交人"
        v-if="receiver">{{receiver}}</el-form-item> -->
      <el-form-item label="通知详情">
        <el-input type="textarea"
                  disabled
                  v-model="content"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary"
                   v-if="file"
                   @click="loadFile(file)">下载附件</el-button>
      </el-form-item>
      <div v-if="type===1">
        <el-form-item>
          <el-upload action="/api/base/uploadByOss.do"
                     :headers="{Authorization:token}"
                     :limit="1"
                     :on-remove="onRemoveFile"
                     ref="upload"
                     :disabled="fileUrl!==''"
                     :on-success="enclosureUploadSuccess"
                     :data="{path:'noticePage',name:''}"
                     :show-file-list="true">
            <el-button type="primary"
                       :disabled="fileUrl!==''">上传资料</el-button>
          </el-upload>
        </el-form-item>
        <el-form-item>
          <el-button type="primary"
                     @click="loadFile(dataUrl)"
                     v-if="dataUrl">下载资料</el-button>
        </el-form-item>
        <el-form-item>
          <el-button type="warning"
                     @click="submit"
                     :disabled="fileUrl!==''"
                     v-if="type===1">提 交</el-button>
        </el-form-item>
      </div>
      <el-form-item>
        <el-button type="primary"
                   @click="loadFile(fileUrl)"
                   v-if="fileUrl">下载资料</el-button>
      </el-form-item>
    </el-form>

  </div>
</template>

<script>
export default {
  name: "noticePage",
  data() {
    return {
      title: this.$route.query.noticeTitle,
      operName: this.$route.query.noticeOperName,
      operTime: this.$route.query.noticeOperTime,
      receiver: this.$route.query.noticeReceiver,
      content: this.$route.query.noticeContent,
      id: this.$route.query.noticeId,
      file: this.$route.query.noticeFile,
      type: 0, //0接收人1提交资料人
      token: sessionStorage.getItem("token"),
      fileUrl: "", //已提交资料
      dataUrl: "",
    };
  },
  created() {
    console.log(this.$route.query.noticeTitle);
    if (this.$route.query.noticeType) {
      this.type = this.$route.query.noticeType;
    }
    if (this.$route.query.noticeFileUrl) {
      this.fileUrl = this.$route.query.noticeFileUrl;
    }
  },
  methods: {
    loadFile(url) {
      window.open(this.$fileUploadDomain + url);
    },
    // 文件移除的钩子
    onRemoveFile(res, file) {
      this.dataUrl = "";
    },
    enclosureUploadSuccess(res, file) {
      this.dataUrl = file.response.message;
    },
    submit() {
      if (this.dataUrl === "") {
        this.$message({
          type: "warning",
          message: "请先上传资料",
        });
        return;
      }
      this.$confirm("是否确定该操作", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$post("/base/updateReadMessage.do", {
            url: this.dataUrl,
            noid: this.id,
          })
            .then((res) => {
              this.read();
              this.$message({
                type: "success",
                message: "提交成功",
              });
              this.$router.go(-1);
            })
            .catch((err) => {
              this.$message({
                type: "warning",
                message: err.message,
              });
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消该操作",
          });
        });
    },
    read() {
      this.$post("/base/updateRead.do", {
        noid: this.id,
      })
        .then((res) => {})
        .catch((err) => {
          this.$message({
            type: "warning",
            message: err.message,
          });
        });
    },
  },
};
</script>

<style scoped lang="scss">
/deep/.el-textarea.is-disabled .el-textarea__inner {
  color: #303030;
}
</style>
